import React, { useState } from 'react';
import './App.css';

function App() {
  const [name, setName] = useState('Visitor');
  const handleClick = () => {
    setName('Anon Consultor');
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to React, {name}!</h1>
        <button onClick={handleClick}>Change Name</button>
      </header>
    </div>
  );
}

export default App;
